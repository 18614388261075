<script setup>
import { useCourses } from "@/composables/useCourses";
import { activeLocale } from "@/composables/useTranslation";
import { transl } from "@/composables/useTranslation";

const { coursesColl } = useCourses();

const categoriesColl = ref([]);
const coursesByCategAll = ref([]);
const coursesByCategVisible = ref([]);

const selectedCategory = ref("all");

function watchProps() {
  watch(
    () => coursesColl.value,
    () => updateCourses(),
    { immediate: true }
  );

  watch(
    () => selectedCategory.value,
    () => updateCourses()
  );

  watch(
    () => activeLocale.value,
    () => updateCourses()
  );
}

function updateCourses() {
  const _coursesByLang = coursesColl.value.filter(
    (el) => el.course_locale == activeLocale.value
  );
  const _coursesByCateg = _coursesByLang.filter(
    (el) =>
      el.course_category == selectedCategory.value || selectedCategory.value == "all"
  );

  const categoriesGroups = groupBy(_coursesByLang, (el) => el.course_category);

  // make categories list, course by categories and visible courses page
  categoriesColl.value = categoriesGroups.map((g) => g[0]);
  coursesByCategAll.value = _coursesByCateg;

  setupVisibleCourses();
}

function setupVisibleCourses() {
  coursesByCategVisible.value = coursesByCategAll.value.slice(0, 8);
}

function openCourseDetails(courseModel) {
  navigateTo(`/course/${courseModel.course_url_token}`)
}

function showMore() {
  const coursesPage = lazyLoad(coursesByCategVisible.value, coursesByCategAll.value, 8);
  coursesByCategVisible.value.push(...coursesPage);
}

function hasMore() {
  return coursesByCategVisible.value.length < coursesByCategAll.value.length;
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <div id="courses" class="courses-wrapper">
    <div class="courses">
      <div class="title">{{ transl("Landing:Our courses") }}</div>

      <v-courses-filter
        class="mt-10"
        :categories="categoriesColl"
        v-model:category="selectedCategory"
      ></v-courses-filter>

      <div class="courses-cards mt-5">
        <div
          class="course-card"
          v-for="course in coursesByCategVisible"
          :key="course._id"
        >
          <img :src="resolveImage(course?.course_image)" alt="" />
          <div class="cards-info">
            <div class="cards-title">{{ course?.course_title }}</div>
            <div class="cards-description">
              {{ course?.course_description }}
            </div>
            <div class="course-button" @click="openCourseDetails(course)">{{ transl("Landing:More") }}</div>
          </div>
        </div>
      </div>
      <div class="more-button mt-9" @click="showMore()" v-if="hasMore()">
        {{ transl("Landing:More courses") }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.courses-wrapper {
  max-width: 1218px;
  padding: 80px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 720px) {
  .courses-wrapper {
    padding: 50px 15px;
  }
}
.courses {
  text-align: center;
}
.cards {
  color: #2a2724;
  font-weight: 700;
  font-size: 50px;
  line-height: 56px;
  text-align: center;
}

.courses-cards {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.course-card {
  aspect-ratio: 280 / 360;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.course-card img {
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.course-button {
  display: inline-flex;
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  color: #fff;
  border-radius: 50px;
  padding: 16px 30px;
  font-size: 13px;
  margin-top: 40px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.cards-info {
  display: none;
}
.course-card:hover .cards-info {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 1;
  color: #fff;
  padding: 32px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
.title {
  font-size: 50px;
  margin: 10px 0;
  line-height: 50px;
  font-weight: 700;
}
@media (max-width: 720px) {
  .title {
    font-size: 36px;
  }
}
.cards-title {
  font-size: 22px;
  margin: 10px 0;
  line-height: 26px;
  font-weight: 700;
  max-height: 80px;
  overflow: hidden;
}
.cards-description {
  max-height: 110px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
}
.more-button {
  color: #fff;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  padding: 19px 37px;
  background: #b1b1b1;
  border-radius: 50px;
  max-width: fit-content;
  font-size: 13px;
  font-weight: 500;
}

@media (hover: hover) {
  .logo:hover {
    cursor: pointer;
  }
  .more-button:hover {
    opacity: 0.8;
  }
}
@media (hover: none) {
  .logo:active {
    cursor: pointer;
  }
  .more-button:active {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .courses-cards {
    grid-template-columns: minmax(200px, 280px) minmax(200px, 280px) minmax(200px, 280px);
    justify-content: center;
  }
}
@media (max-width: 820px) {
  .courses-cards {
    grid-template-columns: minmax(200px, 280px) minmax(200px, 280px);
  }
}
@media (max-width: 580px) {
  .courses-cards {
    grid-template-columns: minmax(200px, 280px);
  }
}
@media (max-width: 480px) {
}
</style>
~/composables/useTranslation~/composables/useTranslation